exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-returns-js": () => import("./../../../src/pages/about/returns.js" /* webpackChunkName: "component---src-pages-about-returns-js" */),
  "component---src-pages-about-shipping-js": () => import("./../../../src/pages/about/shipping.js" /* webpackChunkName: "component---src-pages-about-shipping-js" */),
  "component---src-pages-cart-changed-js": () => import("./../../../src/pages/cart-changed.js" /* webpackChunkName: "component---src-pages-cart-changed-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../../../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sent-js": () => import("./../../../src/pages/sent.js" /* webpackChunkName: "component---src-pages-sent-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-typewriters-gossen-tippa-js": () => import("./../../../src/pages/typewriters/gossen-tippa.js" /* webpackChunkName: "component---src-pages-typewriters-gossen-tippa-js" */),
  "component---src-pages-typewriters-groma-portables-js": () => import("./../../../src/pages/typewriters/groma-portables.js" /* webpackChunkName: "component---src-pages-typewriters-groma-portables-js" */),
  "component---src-pages-typewriters-hermes-portables-js": () => import("./../../../src/pages/typewriters/hermes-portables.js" /* webpackChunkName: "component---src-pages-typewriters-hermes-portables-js" */),
  "component---src-pages-typewriters-rooy-portable-js": () => import("./../../../src/pages/typewriters/rooy-portable.js" /* webpackChunkName: "component---src-pages-typewriters-rooy-portable-js" */),
  "component---src-templates-typewriter-js": () => import("./../../../src/templates/typewriter.js" /* webpackChunkName: "component---src-templates-typewriter-js" */)
}

